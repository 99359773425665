.date-picker {
    height: 3rem;
    width: 90%;
    border-radius: 10px;
    border: 0;
    margin-top: 2vmax;
    text-align: right;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    font-size: 1rem;
}

.react-datepicker-wrapper {
    width: 100%;
}

